import React from 'react';
import Layout from 'components/Layout';
import { graphql, useStaticQuery } from 'gatsby';

const Contact = (props) => {
  const data = useStaticQuery(graphql`
    query {
      contentfulPage(slug: {eq: "contact"} siteId: {eq: "About"}) {
        ...ContactUsPageFragment
      }
    }
  `);

  const pageData = data?.contentfulPage || {};

  return <Layout {...props} {...pageData} />;
};

export default Contact;
